.experience__container{
    display: grid;
    grid-template-columns: 2fr;
    gap: 2rem;
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem, 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    height: min-content;
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}
/*
.experience__frontend:hover{
    transform: rotate(-10deg);

}

.experience__quality_assurance:hover{
    transform: rotate(10deg);
}

.experience__container > div h3 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: var(--color-primary);
}*/

.experience__content {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr;
    row-gap: 2rem;
    column-gap: 1rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.experience__details{
    display: block;
    gap: 0.5rem;
    grid-template-columns: 0.5fr 0.5fr 0.5fr;
}

.experience__details-icon{
    margin-top: 2px;
    color: var(--color-primary);
}


/* MEDIA  QUERIES (MEDIUM DEVICES) */

@media screen and (max-width:1024px) {
    .experience__container{
        grid-template-columns: 1fr;
    }

    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    .experience__content{
        padding: 1rem;
    }
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width:600px) {

    .experience__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
    
}