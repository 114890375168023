*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

.hero{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}

.hero h1{
    font-size: 45px;
    font-weight: 500;
    margin-top: -500px;
    margin-bottom: 50px;
}

.hero h1 span{
    color: #ff2363;
}

textarea{
    width: 600px;
    height: 250px;
    background: transparent;
    color: #fff;
    font-size: 15px;
    outline: 0;
    padding: 20px;
    border-radius: 10px;
    resize: none;
    margin-bottom: 30px;
}

.row{
    width: 600px;
    display: flex;
    align-items: center;
    gap: 20px;
}

button{
    background: #ff2963;
    color: #fff;
    font-size: 16px;
    padding: 10px 30px;
    border-radius: 35px;
    outline: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
}

button img{
    width: 20px;
    margin-right: 10px;
}

select{
    flex: 1;
    color: var(--color-primary-variant);
    background: var(--color-primary-variant);
    height: 50px;
    padding: 0 20px;
    border: 1px;
    outline: 0;
    border-radius: 35px;
    appearance: none;
    background-image: url('../../../assets/dropdown.png');
    background-repeat: no-repeat;
    background-size: 30px;
    background-position-x: calc(100% - 20px);
    background-position-y: center;
}