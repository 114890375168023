/*.portfolio__container{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item{
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}*/

.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 0.35fr);
    gap: 2.5rem;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
}

.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}


/* MEDIA QUERY FOR MEDIUM DEVICES */
@media screen and (max-width: 1024px) {
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }    
}

/* MEDIA QUERY FOR SMALL DEVICES */

@media screen and (max-width: 600px) {
    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}